<template>
  <div id="contact-form-container">
    <!-- Inserted provided script -->
    <div role="main" id="contact-us-website-ada3feb76d16e3e0c3cd"></div>
  </div>
</template>

<script>
export default {
  mounted() {
    const script = document.createElement("script");
    script.src = "https://d335luupugsy2.cloudfront.net/js/rdstation-forms/stable/rdstation-forms.min.js";
    script.onload = () => {
      /* eslint-disable no-undef */
      new RDStationForms('contact-us-website-ada3feb76d16e3e0c3cd', '').createForm();
      /* eslint-enable no-undef */
    };
    document.getElementById('contact-form-container').appendChild(script);
  }
};
</script>

<style>
.input-error::placeholder {
  color: red;
}
.input-error {
  color: red;
}
</style>
